import axios from "axios";

const state = {
  serverLimit: 0,
  connectingTo: {},
  frameGroupData: {
    group1: [
      {
        id: "TI_T35_Merayakan Pemilu_M_17-25_Kurus",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_M_17-25_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_M_17-25_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_M_Kurus",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_M_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_M_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-cowo 25-45 tahun kurus",
        src: require("@/assets/webp/RK-tema naik kuda-cowo 25-45 tahun kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-cowo 25-45 tahun kurus.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-MK-19-25",
        src: require("@/assets/webp/T38-MK-19-25.webp"),
        srcOri: require("@/assets/allTemplate/T38-MK-19-25.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-T5-ALT5-2812",
        src: require("@/assets/webp/RK-T5-ALT5-2812.webp"),
        srcOri: require("@/assets/allTemplate/RK-T5-ALT5-2812.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah - cowo 17-25 kurus",
        src: require("@/assets/webp/RK-tema anak sekolah - cowo 17-25 kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah - cowo 17-25 kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT5-MK17-1801",
        src: require("@/assets/webp/AD-T36-ALT5-MK17-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT5-MK17-1801.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "SA-T11-ALT2-1812",
        src: require("@/assets/webp/SA-T11-ALT2-1812.webp"),
        srcOri: require("@/assets/allTemplate/SA-T11-ALT2-1812.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-1001",
        src: require("@/assets/webp/TI-T35-ALT1-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0501",
        src: require("@/assets/webp/AH-T34-ALT1-0501.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0501.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "RO-T1-ALT1-2212",
        src: require("@/assets/webp/RO-T1-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/RO-T1-ALT1-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "TI-T34-ALT1-2012_1080",
        src: require("@/assets/webp/TI-T34-ALT1-2012_1080.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2012_1080.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "DE-T30-ALT1-M-2212",
        src: require("@/assets/webp/DE-T30-ALT1-M-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT1-M-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT2-2712",
        src: require("@/assets/webp/AH-T30-ALT2-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT2-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1712 cowo kurus 17-25",
        src: require("@/assets/webp/RK-T4-ALT4-1712 cowo kurus 17-25.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1712 cowo kurus 17-25.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RK-T4-ALT5-1212",
        src: require("@/assets/webp/RK-T4-ALT5-1212.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT5-1212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AK-T14-ALT1-0812",
        src: require("@/assets/webp/AK-T14-ALT1-0812.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-ALT1-0812.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-M17-K-ALT6-1312",
        src: require("@/assets/webp/TE-T20-M17-K-ALT6-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-M17-K-ALT6-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T10-ALT2-1212",
        src: require("@/assets/webp/AH-T10-ALT2-1212.webp"),
        srcOri: require("@/assets/allTemplate/AH-T10-ALT2-1212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "BI-12-ALT02-2012",
        src: require("@/assets/webp/BI-12-ALT02-2012.webp"),
        srcOri: require("@/assets/allTemplate/BI-12-ALT02-2012.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AD-T2-ALT1-1212",
        src: require("@/assets/webp/AD-T2-ALT1-1212.webp"),
        srcOri: require("@/assets/allTemplate/AD-T2-ALT1-1212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT6-1512",
        src: require("@/assets/webp/TI-T13-ALT6-1512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT6-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-ALT01-0712",
        src: require("@/assets/webp/RE-T17-ALT01-0712.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-ALT01-0712.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
    ],
    group2: [
      {
        id: "TI_T35_Merayakan Pemilu_M_17-25_Kurus",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_M_17-25_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_M_17-25_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_M_Kurus",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_M_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_M_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-cowo 25-45 tahun kurus",
        src: require("@/assets/webp/RK-tema naik kuda-cowo 25-45 tahun kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-cowo 25-45 tahun kurus.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-MK-26-45",
        src: require("@/assets/webp/T38-MK-26-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-MK-26-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-T5-ALT11-0201",
        src: require("@/assets/webp/RK-T5-ALT11-0201.webp"),
        srcOri: require("@/assets/allTemplate/RK-T5-ALT11-0201.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah - cowo 25-42 kurus",
        src: require("@/assets/webp/RK-tema anak sekolah - cowo 25-42 kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah - cowo 25-42 kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT2-MK26-1801",
        src: require("@/assets/webp/AD-T36-ALT2-MK26-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT2-MK26-1801.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "SA-T11-ALT2-1912",
        src: require("@/assets/webp/SA-T11-ALT2-1912.webp"),
        srcOri: require("@/assets/allTemplate/SA-T11-ALT2-1912.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-1001",
        src: require("@/assets/webp/TI-T35-ALT1-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0501",
        src: require("@/assets/webp/AH-T34-ALT1-0501.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0501.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "RO-T1-ALT1-2212",
        src: require("@/assets/webp/RO-T1-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/RO-T1-ALT1-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "TI-T34-ALT1-2012_1080",
        src: require("@/assets/webp/TI-T34-ALT1-2012_1080.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2012_1080.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "DE-T30-ALT1-M-2212",
        src: require("@/assets/webp/DE-T30-ALT1-M-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT1-M-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT2-2712",
        src: require("@/assets/webp/AH-T30-ALT2-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT2-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT5-1212",
        src: require("@/assets/webp/RK-T4-ALT5-1212.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT5-1212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AK-T14-ALT1-0812",
        src: require("@/assets/webp/AK-T14-ALT1-0812.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-ALT1-0812.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-M26-K-ALT8-1312",
        src: require("@/assets/webp/TE-T20-M26-K-ALT8-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-M26-K-ALT8-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T10-ALT1-1312",
        src: require("@/assets/webp/AH-T10-ALT1-1312.webp"),
        srcOri: require("@/assets/allTemplate/AH-T10-ALT1-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "BI-12-ALT03-1812",
        src: require("@/assets/webp/BI-12-ALT03-1812.webp"),
        srcOri: require("@/assets/allTemplate/BI-12-ALT03-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT3-1512",
        src: require("@/assets/webp/TI-T13-ALT3-1512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT3-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-ALT01-0712",
        src: require("@/assets/webp/RE-T17-ALT01-0712.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-ALT01-0712.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
    ],
    group3: [
      {
        id: "TI_T35_Merayakan Pemilu_M_17-25_Kurus",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_M_17-25_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_M_17-25_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_M_Kurus",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_M_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_M_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-cowo kurus 42+ tahun",
        src: require("@/assets/webp/RK-tema naik kuda-cowo kurus 42+ tahun.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-cowo kurus 42+ tahun.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-MK-45",
        src: require("@/assets/webp/T38-MK-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-MK-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-T5-ALT17-0301",
        src: require("@/assets/webp/RK-T5-ALT17-0301.webp"),
        srcOri: require("@/assets/allTemplate/RK-T5-ALT17-0301.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah - cowo 42+ kurus",
        src: require("@/assets/webp/RK-tema anak sekolah - cowo 42+ kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah - cowo 42+ kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT6-MK45-1801",
        src: require("@/assets/webp/AD-T36-ALT6-MK45-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT6-MK45-1801.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "SA-T11-ALT1-1812",
        src: require("@/assets/webp/SA-T11-ALT1-1812.webp"),
        srcOri: require("@/assets/allTemplate/SA-T11-ALT1-1812.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-1001",
        src: require("@/assets/webp/TI-T35-ALT1-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0501",
        src: require("@/assets/webp/AH-T34-ALT1-0501.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0501.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T34-ALT1-2612",
        src: require("@/assets/webp/TI-T34-ALT1-2612.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "DE-T30-ALT2-M-2212",
        src: require("@/assets/webp/DE-T30-ALT2-M-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT2-M-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT4-2712",
        src: require("@/assets/webp/AH-T30-ALT4-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT4-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT13-2212 43+ cowo kurus",
        src: require("@/assets/webp/RK-T4-ALT13-2212 43+ cowo kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT13-2212 43+ cowo kurus.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T10-ALT1-1212",
        src: require("@/assets/webp/AH-T10-ALT1-1212.webp"),
        srcOri: require("@/assets/allTemplate/AH-T10-ALT1-1212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "BI-12-ALT02-1812",
        src: require("@/assets/webp/BI-12-ALT02-1812.webp"),
        srcOri: require("@/assets/allTemplate/BI-12-ALT02-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT4-1512",
        src: require("@/assets/webp/TI-T13-ALT4-1512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT4-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
    ],
    group4: [
      {
        id: "TI_T35_Merayakan Pemilu_M_17-25_Gemuk",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_M_17-25_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_M_17-25_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_M_Gemuk",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_M_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_M_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-18-25 tahun cowo gendut",
        src: require("@/assets/webp/RK-tema naik kuda-18-25 tahun cowo gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-18-25 tahun cowo gendut.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-MF-17-25",
        src: require("@/assets/webp/T38-MF-17-25.webp"),
        srcOri: require("@/assets/allTemplate/T38-MF-17-25.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 17-25 cowo gendut",
        src: require("@/assets/webp/RK-mancing bersama- 17-25 cowo gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 17-25 cowo gendut.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah - cowo 17-25 gendut",
        src: require("@/assets/webp/RK-tema anak sekolah - cowo 17-25 gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah - cowo 17-25 gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT18-MG17-1801",
        src: require("@/assets/webp/AD-T36-ALT18-MG17-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT18-MG17-1801.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "SA-T11-ALT1-1912",
        src: require("@/assets/webp/SA-T11-ALT1-1912.webp"),
        srcOri: require("@/assets/allTemplate/SA-T11-ALT1-1912.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-0501",
        src: require("@/assets/webp/TI-T35-ALT1-0501.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-0501.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT2-0501",
        src: require("@/assets/webp/AH-T34-ALT2-0501.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT2-0501.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "SA-T1-ALT2-0301",
        src: require("@/assets/webp/SA-T1-ALT2-0301.webp"),
        srcOri: require("@/assets/allTemplate/SA-T1-ALT2-0301.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AK-T34-M17-25G",
        src: require("@/assets/webp/AK-T34-M17-25G.webp"),
        srcOri: require("@/assets/allTemplate/AK-T34-M17-25G.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "DE-T30-ALT3-2212",
        src: require("@/assets/webp/DE-T30-ALT3-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT3-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT3-2712",
        src: require("@/assets/webp/AH-T30-ALT3-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT3-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1912 cowo gendut 17-25",
        src: require("@/assets/webp/RK-T4-ALT4-1912 cowo gendut 17-25.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1912 cowo gendut 17-25.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AK-T14-M17-43g",
        src: require("@/assets/webp/AK-T14-M17-43g.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-M17-43g.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-M17-G-ALT5-1312",
        src: require("@/assets/webp/TE-T20-M17-G-ALT5-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-M17-G-ALT5-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T10-ALT3-1312",
        src: require("@/assets/webp/AH-T10-ALT3-1312.webp"),
        srcOri: require("@/assets/allTemplate/AH-T10-ALT3-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "BI-12-ALT01-2012",
        src: require("@/assets/webp/BI-12-ALT01-2012.webp"),
        srcOri: require("@/assets/allTemplate/BI-12-ALT01-2012.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AD-T2-ALT1-2012",
        src: require("@/assets/webp/AD-T2-ALT1-2012.webp"),
        srcOri: require("@/assets/allTemplate/AD-T2-ALT1-2012.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT1-0812",
        src: require("@/assets/webp/TI-T13-ALT1-0812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT1-0812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-M17-42G",
        src: require("@/assets/webp/RE-T17-M17-42G.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-M17-42G.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
    ],
    group5: [
      {
        id: "TI_T35_Merayakan Pemilu_M_17-25_Gemuk",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_M_17-25_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_M_17-25_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_M_Gemuk",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_M_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_M_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda - 25-42 tahun - cowo - gendut",
        src: require("@/assets/webp/RK-tema naik kuda - 25-42 tahun - cowo - gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda - 25-42 tahun - cowo - gendut.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-MF-26-45",
        src: require("@/assets/webp/T38-MF-26-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-MF-26-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "mancing (2)",
        src: require("@/assets/webp/mancing (2).webp"),
        srcOri: require("@/assets/allTemplate/mancing (2).png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah - cowo 25-42 gendut",
        src: require("@/assets/webp/RK-tema anak sekolah - cowo 25-42 gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah - cowo 25-42 gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT17-MG26-1801",
        src: require("@/assets/webp/AD-T36-ALT17-MG26-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT17-MG26-1801.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "SA-T11-ALT1-2212",
        src: require("@/assets/webp/SA-T11-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/SA-T11-ALT1-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-0501",
        src: require("@/assets/webp/TI-T35-ALT1-0501.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-0501.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT2-0501",
        src: require("@/assets/webp/AH-T34-ALT2-0501.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT2-0501.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "SA-T1-ALT1-0301",
        src: require("@/assets/webp/SA-T1-ALT1-0301.webp"),
        srcOri: require("@/assets/allTemplate/SA-T1-ALT1-0301.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AK-T34-M17-25G",
        src: require("@/assets/webp/AK-T34-M17-25G.webp"),
        srcOri: require("@/assets/allTemplate/AK-T34-M17-25G.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "DE-T30-ALT3-2212",
        src: require("@/assets/webp/DE-T30-ALT3-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT3-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT3-2712",
        src: require("@/assets/webp/AH-T30-ALT3-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT3-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "AK-T14-M17-43g",
        src: require("@/assets/webp/AK-T14-M17-43g.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-M17-43g.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-M26-G-ALT7-1312",
        src: require("@/assets/webp/TE-T20-M26-G-ALT7-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-M26-G-ALT7-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T10-ALT2-1312",
        src: require("@/assets/webp/AH-T10-ALT2-1312.webp"),
        srcOri: require("@/assets/allTemplate/AH-T10-ALT2-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "BI-12-ALT01-1812",
        src: require("@/assets/webp/BI-12-ALT01-1812.webp"),
        srcOri: require("@/assets/allTemplate/BI-12-ALT01-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT2-1512",
        src: require("@/assets/webp/TI-T13-ALT2-1512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT2-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-M17-42G",
        src: require("@/assets/webp/RE-T17-M17-42G.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-M17-42G.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
      {
        id: "hiking",
        src: require("@/assets/webp/hiking.webp"),
        srcOri: require("@/assets/allTemplate/hiking.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
    ],
    group6: [
      {
        id: "TI_T35_Merayakan Pemilu_M_17-25_Gemuk",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_M_17-25_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_M_17-25_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_M_Gemuk",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_M_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_M_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda - cowo - 42+ tahun - gendut",
        src: require("@/assets/webp/RK-tema naik kuda - cowo - 42+ tahun - gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda - cowo - 42+ tahun - gendut.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-MF-45",
        src: require("@/assets/webp/T38-MF-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-MF-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 42+ endut_",
        src: require("@/assets/webp/RK-mancing bersama- 42+ endut_.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 42+ endut_.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah - cowo 42+ gendut",
        src: require("@/assets/webp/RK-tema anak sekolah - cowo 42+ gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah - cowo 42+ gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT16-MG45-1801",
        src: require("@/assets/webp/AD-T36-ALT16-MG45-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT16-MG45-1801.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "SA-T11-ALT1-1212",
        src: require("@/assets/webp/SA-T11-ALT1-1212.webp"),
        srcOri: require("@/assets/allTemplate/SA-T11-ALT1-1212.png"),
        faceIndexTarget: 0,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-0501",
        src: require("@/assets/webp/TI-T35-ALT1-0501.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-0501.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT2-0501",
        src: require("@/assets/webp/AH-T34-ALT2-0501.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT2-0501.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AK-T34-M43G",
        src: require("@/assets/webp/AK-T34-M43G.webp"),
        srcOri: require("@/assets/allTemplate/AK-T34-M43G.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "DE-T30-ALT3-2212",
        src: require("@/assets/webp/DE-T30-ALT3-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT3-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT5-2712",
        src: require("@/assets/webp/AH-T30-ALT5-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT5-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-0812",
        src: require("@/assets/webp/RK-T4-ALT4-0812.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-0812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T10-ALT1-0812",
        src: require("@/assets/webp/AH-T10-ALT1-0812.webp"),
        srcOri: require("@/assets/allTemplate/AH-T10-ALT1-0812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "BI-12-ALT02-1912",
        src: require("@/assets/webp/BI-12-ALT02-1912.webp"),
        srcOri: require("@/assets/allTemplate/BI-12-ALT02-1912.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT5-1512",
        src: require("@/assets/webp/TI-T13-ALT5-1512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT5-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
    ],
    group7: [
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Kurus",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Kurus",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Kurus_Hijab",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Kurus_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Kurus_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-cewe 17-25 tahun - kurus ",
        src: require("@/assets/webp/RK-tema naik kuda-cewe 17-25 tahun - kurus .webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-cewe 17-25 tahun - kurus .png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-cewe 17-25 tahun - kurus -  hijab",
        src: require("@/assets/webp/RK-tema naik kuda-cewe 17-25 tahun - kurus -  hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-cewe 17-25 tahun - kurus -  hijab.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-FK-17-25",
        src: require("@/assets/webp/T38-FK-17-25.webp"),
        srcOri: require("@/assets/allTemplate/T38-FK-17-25.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "T38-FKH-17-25",
        src: require("@/assets/webp/T38-FKH-17-25.webp"),
        srcOri: require("@/assets/allTemplate/T38-FKH-17-25.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-T5-ALT3-2812",
        src: require("@/assets/webp/RK-T5-ALT3-2812.webp"),
        srcOri: require("@/assets/allTemplate/RK-T5-ALT3-2812.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 17-25 cewe kurus hijab",
        src: require("@/assets/webp/RK-mancing bersama- 17-25 cewe kurus hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 17-25 cewe kurus hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah 17- 25 cewe kurus",
        src: require("@/assets/webp/RK-tema anak sekolah 17- 25 cewe kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah 17- 25 cewe kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "BI-tema anak sekolah - cewe 17+ hijab kurus",
        src: require("@/assets/webp/BI-tema anak sekolah - cewe 17+ hijab kurus.webp"),
        srcOri: require("@/assets/allTemplate/BI-tema anak sekolah - cewe 17+ hijab kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT3-WK17-1801",
        src: require("@/assets/webp/AD-T36-ALT3-WK17-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT3-WK17-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "AD-T36-ALT8-WKH17-1801",
        src: require("@/assets/webp/AD-T36-ALT8-WKH17-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT8-WKH17-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-0801",
        src: require("@/assets/webp/TI-T35-ALT1-0801.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-0801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T35-ALT4-1001",
        src: require("@/assets/webp/TI-T35-ALT4-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT4-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0801",
        src: require("@/assets/webp/AH-T34-ALT1-0801.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0801.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT2-0901",
        src: require("@/assets/webp/AH-T34-ALT2-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT2-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "RK-T1-ALT1-2712",
        src: require("@/assets/webp/RK-T1-ALT1-2712.webp"),
        srcOri: require("@/assets/allTemplate/RK-T1-ALT1-2712.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AH-T1-ALT2-0201",
        src: require("@/assets/webp/AH-T1-ALT2-0201.webp"),
        srcOri: require("@/assets/allTemplate/AH-T1-ALT2-0201.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "TI-T34-ALT1-2212",
        src: require("@/assets/webp/TI-T34-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T34-ALT2-2212",
        src: require("@/assets/webp/TI-T34-ALT2-2212.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT2-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT1-2612",
        src: require("@/assets/webp/AH-T30-ALT1-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT1-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT4-2612",
        src: require("@/assets/webp/AH-T30-ALT4-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT4-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT1-2712",
        src: require("@/assets/webp/AH-T30-ALT1-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT1-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "JE-T30-ALT1-2212",
        src: require("@/assets/webp/JE-T30-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT1-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "DE-T30-ALT5-F-2212",
        src: require("@/assets/webp/DE-T30-ALT5-F-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT5-F-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "DE-T30-ALT6-F-H-2212",
        src: require("@/assets/webp/DE-T30-ALT6-F-H-2212.webp"),
        srcOri: require("@/assets/allTemplate/DE-T30-ALT6-F-H-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1612 cewe 17-25",
        src: require("@/assets/webp/RK-T4-ALT4-1612 cewe 17-25.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1612 cewe 17-25.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1512 hijab",
        src: require("@/assets/webp/RK-T4-ALT4-1512 hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1512 hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43K",
        src: require("@/assets/webp/AK-T14-F17-43K.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43K.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43KJ",
        src: require("@/assets/webp/AK-T14-F17-43KJ.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43KJ.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-F17-K-ALT4-1312",
        src: require("@/assets/webp/TE-T20-F17-K-ALT4-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F17-K-ALT4-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TE-T20-F17-K-H-ALT3-1312",
        src: require("@/assets/webp/TE-T20-F17-K-H-ALT3-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F17-K-H-ALT3-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AD-T2-ALT1-2112",
        src: require("@/assets/webp/AD-T2-ALT1-2112.webp"),
        srcOri: require("@/assets/allTemplate/AD-T2-ALT1-2112.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AD-T2-ALT2-1812",
        src: require("@/assets/webp/AD-T2-ALT2-1812.webp"),
        srcOri: require("@/assets/allTemplate/AD-T2-ALT2-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT4-1812",
        src: require("@/assets/webp/TI-T13-ALT4-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT4-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT7-1512",
        src: require("@/assets/webp/TI-T13-ALT7-1512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT7-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-K-NH-ALT04-1812",
        src: require("@/assets/webp/RE-T17-F-17-K-NH-ALT04-1812.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-K-NH-ALT04-1812.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-K-H-ALT01-1612",
        src: require("@/assets/webp/RE-T17-F-17-K-H-ALT01-1612.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-K-H-ALT01-1612.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
    ],
    group8: [
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Kurus",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Kurus",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Kurus_Hijab",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Kurus_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Kurus_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda - cewe 25-42 - kurus",
        src: require("@/assets/webp/RK-tema naik kuda - cewe 25-42 - kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda - cewe 25-42 - kurus.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-cewe 17-25 tahun - kurus -  hijab",
        src: require("@/assets/webp/RK-tema naik kuda-cewe 17-25 tahun - kurus -  hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-cewe 17-25 tahun - kurus -  hijab.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-FK-26-45",
        src: require("@/assets/webp/T38-FK-26-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FK-26-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "T38-FKH-26-45",
        src: require("@/assets/webp/T38-FKH-26-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FKH-26-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 25-42 cewe kurus",
        src: require("@/assets/webp/RK-mancing bersama- 25-42 cewe kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 25-42 cewe kurus.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 25-42 cewe kurus hijab",
        src: require("@/assets/webp/RK-mancing bersama- 25-42 cewe kurus hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 25-42 cewe kurus hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah 25-42 cewe kurus",
        src: require("@/assets/webp/RK-tema anak sekolah 25-42 cewe kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah 25-42 cewe kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "BI-tema anak sekolah - cewe 17+ hijab kurus",
        src: require("@/assets/webp/BI-tema anak sekolah - cewe 17+ hijab kurus.webp"),
        srcOri: require("@/assets/allTemplate/BI-tema anak sekolah - cewe 17+ hijab kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT1-WK26-1801",
        src: require("@/assets/webp/AD-T36-ALT1-WK26-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT1-WK26-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "AD-T36-ALT13-WHK26-1801",
        src: require("@/assets/webp/AD-T36-ALT13-WHK26-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT13-WHK26-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-0801",
        src: require("@/assets/webp/TI-T35-ALT1-0801.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-0801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T35-ALT4-1001",
        src: require("@/assets/webp/TI-T35-ALT4-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT4-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0801",
        src: require("@/assets/webp/AH-T34-ALT1-0801.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0801.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT2-0901",
        src: require("@/assets/webp/AH-T34-ALT2-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT2-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "RK-T1-ALT1-2712",
        src: require("@/assets/webp/RK-T1-ALT1-2712.webp"),
        srcOri: require("@/assets/allTemplate/RK-T1-ALT1-2712.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AH-T1-ALT2-0201",
        src: require("@/assets/webp/AH-T1-ALT2-0201.webp"),
        srcOri: require("@/assets/allTemplate/AH-T1-ALT2-0201.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "TI-T34-ALT1-2212",
        src: require("@/assets/webp/TI-T34-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T34-ALT2-2212",
        src: require("@/assets/webp/TI-T34-ALT2-2212.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT2-2212.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT1-2612",
        src: require("@/assets/webp/AH-T30-ALT1-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT1-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT4-2612",
        src: require("@/assets/webp/AH-T30-ALT4-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT4-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT1-2712",
        src: require("@/assets/webp/AH-T30-ALT1-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT1-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "JE-T30-ALT1-2212",
        src: require("@/assets/webp/JE-T30-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT1-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1612 cewe 17-25",
        src: require("@/assets/webp/RK-T4-ALT4-1612 cewe 17-25.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1612 cewe 17-25.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1912 cewe kurus 26-42 hijab",
        src: require("@/assets/webp/RK-T4-ALT4-1912 cewe kurus 26-42 hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1912 cewe kurus 26-42 hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43K",
        src: require("@/assets/webp/AK-T14-F17-43K.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43K.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43KJ",
        src: require("@/assets/webp/AK-T14-F17-43KJ.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43KJ.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-F30-K-ALT9-1512",
        src: require("@/assets/webp/TE-T20-F30-K-ALT9-1512.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F30-K-ALT9-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TE-T20-F40-K-H-ALT10-1312",
        src: require("@/assets/webp/TE-T20-F40-K-H-ALT10-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F40-K-H-ALT10-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT6-1812",
        src: require("@/assets/webp/TI-T13-ALT6-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT6-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT3-1812",
        src: require("@/assets/webp/TI-T13-ALT3-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT3-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-K-NH-ALT04-1812",
        src: require("@/assets/webp/RE-T17-F-17-K-NH-ALT04-1812.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-K-NH-ALT04-1812.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-K-H-ALT01-1612",
        src: require("@/assets/webp/RE-T17-F-17-K-H-ALT01-1612.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-K-H-ALT01-1612.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
    ],
    group9: [
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Kurus",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Kurus_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Kurus",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Kurus.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Kurus.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Kurus_Hijab",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Kurus_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Kurus_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda 42+ tahun - cewe - kurus",
        src: require("@/assets/webp/RK-tema naik kuda 42+ tahun - cewe - kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda 42+ tahun - cewe - kurus.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda - cewe 42+ -  kurus - hijab",
        src: require("@/assets/webp/RK-tema naik kuda - cewe 42+ -  kurus - hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda - cewe 42+ -  kurus - hijab.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-FK-45",
        src: require("@/assets/webp/T38-FK-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FK-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "T38-FKH-45",
        src: require("@/assets/webp/T38-FKH-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FKH-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 42+ cewe kurus",
        src: require("@/assets/webp/RK-mancing bersama- 42+ cewe kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 42+ cewe kurus.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 42+ cewe kurus hijab",
        src: require("@/assets/webp/RK-mancing bersama- 42+ cewe kurus hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 42+ cewe kurus hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah 42+cewe kurus",
        src: require("@/assets/webp/RK-tema anak sekolah 42+cewe kurus.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah 42+cewe kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "BI-tema anak sekolah - cewe hijab 42+ kurus",
        src: require("@/assets/webp/BI-tema anak sekolah - cewe hijab 42+ kurus.webp"),
        srcOri: require("@/assets/allTemplate/BI-tema anak sekolah - cewe hijab 42+ kurus.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT4-WK45-1801",
        src: require("@/assets/webp/AD-T36-ALT4-WK45-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT4-WK45-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "AD-T36-ALT14-WHK45-1801",
        src: require("@/assets/webp/AD-T36-ALT14-WHK45-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT14-WHK45-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT1-0801",
        src: require("@/assets/webp/TI-T35-ALT1-0801.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT1-0801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T35-ALT4-1001",
        src: require("@/assets/webp/TI-T35-ALT4-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT4-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0801",
        src: require("@/assets/webp/AH-T34-ALT1-0801.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0801.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT2-0901",
        src: require("@/assets/webp/AH-T34-ALT2-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT2-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AD-T1-F42-H-ALT1-020123",
        src: require("@/assets/webp/AD-T1-F42-H-ALT1-020123.webp"),
        srcOri: require("@/assets/allTemplate/AD-T1-F42-H-ALT1-020123.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "TI-T34-ALT4-2712",
        src: require("@/assets/webp/TI-T34-ALT4-2712.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT4-2712.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T34-ALT2-2712",
        src: require("@/assets/webp/TI-T34-ALT2-2712.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT2-2712.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT4-2612",
        src: require("@/assets/webp/AH-T30-ALT4-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT4-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT1-2712",
        src: require("@/assets/webp/AH-T30-ALT1-2712.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT1-2712.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "JE-T30-ALT1-2212",
        src: require("@/assets/webp/JE-T30-ALT1-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT1-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "AH-T30-ALT6-2612",
        src: require("@/assets/webp/AH-T30-ALT6-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT6-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT1-1912",
        src: require("@/assets/webp/TI-T13-ALT1-1912.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT1-1912.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT3-1812",
        src: require("@/assets/webp/TI-T13-ALT3-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT3-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
    ],
    group10: [
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Gemuk",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Gemuk",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Gemuk_Hijab",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Gemuk_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Gemuk_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda-17-25 tahun - cewe - gendut",
        src: require("@/assets/webp/RK-tema naik kuda-17-25 tahun - cewe - gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda-17-25 tahun - cewe - gendut.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda - 18-25 tahun - cewe gendut - hijab",
        src: require("@/assets/webp/RK-tema naik kuda - 18-25 tahun - cewe gendut - hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda - 18-25 tahun - cewe gendut - hijab.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-FF-17-25",
        src: require("@/assets/webp/T38-FF-17-25.webp"),
        srcOri: require("@/assets/allTemplate/T38-FF-17-25.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "T38-FFH-17-25",
        src: require("@/assets/webp/T38-FFH-17-25.webp"),
        srcOri: require("@/assets/allTemplate/T38-FFH-17-25.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 17-25 cewe gendut",
        src: require("@/assets/webp/RK-mancing bersama- 17-25 cewe gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 17-25 cewe gendut.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 17-25 cewe gendut hijab",
        src: require("@/assets/webp/RK-mancing bersama- 17-25 cewe gendut hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 17-25 cewe gendut hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah 17- 25 cewe gendut",
        src: require("@/assets/webp/RK-tema anak sekolah 17- 25 cewe gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah 17- 25 cewe gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "BI-tema anak sekolah - cewe 17+ hijab gendut",
        src: require("@/assets/webp/BI-tema anak sekolah - cewe 17+ hijab gendut.webp"),
        srcOri: require("@/assets/allTemplate/BI-tema anak sekolah - cewe 17+ hijab gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT10-WG17-1801",
        src: require("@/assets/webp/AD-T36-ALT10-WG17-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT10-WG17-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "AD-T36-ALT11-WHG17-1801",
        src: require("@/assets/webp/AD-T36-ALT11-WHG17-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT11-WHG17-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT2-1001",
        src: require("@/assets/webp/TI-T35-ALT2-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT2-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T35-ALT3-1001",
        src: require("@/assets/webp/TI-T35-ALT3-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT3-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0901",
        src: require("@/assets/webp/AH-T34-ALT1-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT3-0901",
        src: require("@/assets/webp/AH-T34-ALT3-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT3-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AD-T1-F17-ALT2-020123",
        src: require("@/assets/webp/AD-T1-F17-ALT2-020123.webp"),
        srcOri: require("@/assets/allTemplate/AD-T1-F17-ALT2-020123.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AH-T1-ALT3-0201",
        src: require("@/assets/webp/AH-T1-ALT3-0201.webp"),
        srcOri: require("@/assets/allTemplate/AH-T1-ALT3-0201.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AK-T34-F26-43g",
        src: require("@/assets/webp/AK-T34-F26-43g.webp"),
        srcOri: require("@/assets/allTemplate/AK-T34-F26-43g.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T34-ALT1-2512",
        src: require("@/assets/webp/TI-T34-ALT1-2512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT3-2612",
        src: require("@/assets/webp/AH-T30-ALT3-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT3-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT2-2612",
        src: require("@/assets/webp/AH-T30-ALT2-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT2-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "JE-T30-ALT3-2212",
        src: require("@/assets/webp/JE-T30-ALT3-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT3-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "JE-T30-ALT2-2212",
        src: require("@/assets/webp/JE-T30-ALT2-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT2-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1612 cewe 17-25 gendut",
        src: require("@/assets/webp/RK-T4-ALT4-1612 cewe 17-25 gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1612 cewe 17-25 gendut.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1612 cewe 17-25 gendut hijab",
        src: require("@/assets/webp/RK-T4-ALT4-1612 cewe 17-25 gendut hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1612 cewe 17-25 gendut hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43G",
        src: require("@/assets/webp/AK-T14-F17-43G.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43G.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43GJ",
        src: require("@/assets/webp/AK-T14-F17-43GJ.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43GJ.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-F17-G-ALT1-1312",
        src: require("@/assets/webp/TE-T20-F17-G-ALT1-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F17-G-ALT1-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TE-T20-F17-G-H-ALT2-1312",
        src: require("@/assets/webp/TE-T20-F17-G-H-ALT2-1312.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F17-G-H-ALT2-1312.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AD-T2-ALT3-2012",
        src: require("@/assets/webp/AD-T2-ALT3-2012.webp"),
        srcOri: require("@/assets/allTemplate/AD-T2-ALT3-2012.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AD-T2-ALT1-1812",
        src: require("@/assets/webp/AD-T2-ALT1-1812.webp"),
        srcOri: require("@/assets/allTemplate/AD-T2-ALT1-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT1-1512",
        src: require("@/assets/webp/TI-T13-ALT1-1512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT1-1512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT1-1812",
        src: require("@/assets/webp/TI-T13-ALT1-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT1-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-G-H-ALT01-1812",
        src: require("@/assets/webp/RE-T17-F-17-G-H-ALT01-1812.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-G-H-ALT01-1812.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-G-NH-ALT01-1612",
        src: require("@/assets/webp/RE-T17-F-17-G-NH-ALT01-1612.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-G-NH-ALT01-1612.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
    ],
    group11: [
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Gemuk",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Gemuk",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Gemuk_Hijab",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Gemuk_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Gemuk_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda - 25-42 tahun - cewe gendut",
        src: require("@/assets/webp/RK-tema naik kuda - 25-42 tahun - cewe gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda - 25-42 tahun - cewe gendut.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda 25-42 tahun cewe gendut hijab",
        src: require("@/assets/webp/RK-tema naik kuda 25-42 tahun cewe gendut hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda 25-42 tahun cewe gendut hijab.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-FF-26-45",
        src: require("@/assets/webp/T38-FF-26-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FF-26-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "T38-FFH-26-45",
        src: require("@/assets/webp/T38-FFH-26-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FFH-26-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 25-42 cewe gendut",
        src: require("@/assets/webp/RK-mancing bersama- 25-42 cewe gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 25-42 cewe gendut.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 25-42 cewe gendut hijab",
        src: require("@/assets/webp/RK-mancing bersama- 25-42 cewe gendut hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 25-42 cewe gendut hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah 25-42 cewe gendut",
        src: require("@/assets/webp/RK-tema anak sekolah 25-42 cewe gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah 25-42 cewe gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "BI-tema anak sekolah - cewe 17+ hijab gendut",
        src: require("@/assets/webp/BI-tema anak sekolah - cewe 17+ hijab gendut.webp"),
        srcOri: require("@/assets/allTemplate/BI-tema anak sekolah - cewe 17+ hijab gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT7-WG26-1801",
        src: require("@/assets/webp/AD-T36-ALT7-WG26-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT7-WG26-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "AD-T36-ALT12-WHG26-1801",
        src: require("@/assets/webp/AD-T36-ALT12-WHG26-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT12-WHG26-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT2-1001",
        src: require("@/assets/webp/TI-T35-ALT2-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT2-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T35-ALT3-1001",
        src: require("@/assets/webp/TI-T35-ALT3-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT3-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0901",
        src: require("@/assets/webp/AH-T34-ALT1-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT3-0901",
        src: require("@/assets/webp/AH-T34-ALT3-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT3-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AD-T1-F17-ALT2-020123",
        src: require("@/assets/webp/AD-T1-F17-ALT2-020123.webp"),
        srcOri: require("@/assets/allTemplate/AD-T1-F17-ALT2-020123.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AH-T1-ALT3-0201",
        src: require("@/assets/webp/AH-T1-ALT3-0201.webp"),
        srcOri: require("@/assets/allTemplate/AH-T1-ALT3-0201.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 1,
      },
      {
        id: "AK-T34-F26-43g",
        src: require("@/assets/webp/AK-T34-F26-43g.webp"),
        srcOri: require("@/assets/allTemplate/AK-T34-F26-43g.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T34-ALT1-2512",
        src: require("@/assets/webp/TI-T34-ALT1-2512.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2512.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT3-2612",
        src: require("@/assets/webp/AH-T30-ALT3-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT3-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT2-2612",
        src: require("@/assets/webp/AH-T30-ALT2-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT2-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "JE-T30-ALT3-2212",
        src: require("@/assets/webp/JE-T30-ALT3-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT3-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "JE-T30-ALT2-2212",
        src: require("@/assets/webp/JE-T30-ALT2-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT2-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1612 cewe 17-25 gendut",
        src: require("@/assets/webp/RK-T4-ALT4-1612 cewe 17-25 gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1612 cewe 17-25 gendut.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RK-T4-ALT4-1612 cewe 17-25 gendut hijab",
        src: require("@/assets/webp/RK-T4-ALT4-1612 cewe 17-25 gendut hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT4-1612 cewe 17-25 gendut hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43G",
        src: require("@/assets/webp/AK-T14-F17-43G.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43G.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "AK-T14-F17-43GJ",
        src: require("@/assets/webp/AK-T14-F17-43GJ.webp"),
        srcOri: require("@/assets/allTemplate/AK-T14-F17-43GJ.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "TE-T20-F40-G-ALT10-1313",
        src: require("@/assets/webp/TE-T20-F40-G-ALT10-1313.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F40-G-ALT10-1313.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TE-T20-F40-G-H-ALT10-1314",
        src: require("@/assets/webp/TE-T20-F40-G-H-ALT10-1314.webp"),
        srcOri: require("@/assets/allTemplate/TE-T20-F40-G-H-ALT10-1314.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT2-1812",
        src: require("@/assets/webp/TI-T13-ALT2-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT2-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT5-1812",
        src: require("@/assets/webp/TI-T13-ALT5-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT5-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-G-H-ALT01-1812",
        src: require("@/assets/webp/RE-T17-F-17-G-H-ALT01-1812.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-G-H-ALT01-1812.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
      {
        id: "RE-T17-F-17-G-NH-ALT01-1612",
        src: require("@/assets/webp/RE-T17-F-17-G-NH-ALT01-1612.webp"),
        srcOri: require("@/assets/allTemplate/RE-T17-F-17-G-NH-ALT01-1612.png"),
        faceIndexTarget: 2,
        newImage: false,
      },
    ],
    group12: [
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Gemuk",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab",
        src: require("@/assets/webp/TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/TI_T35_Merayakan Pemilu_F_17-25_Gemuk_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 7,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Gemuk",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Gemuk.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Gemuk.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RY_Naik Vespa_17-25_F_Gemuk_Hijab",
        src: require("@/assets/webp/RY_Naik Vespa_17-25_F_Gemuk_Hijab.webp"),
        srcOri: require("@/assets/allTemplate/RY_Naik Vespa_17-25_F_Gemuk_Hijab.png"),
        faceIndexTarget: 2,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda 42+ tahun - cewe - gendut",
        src: require("@/assets/webp/RK-tema naik kuda 42+ tahun - cewe - gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda 42+ tahun - cewe - gendut.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "RK-tema naik kuda 42+ tahun - cewe - gendut - hijab",
        src: require("@/assets/webp/RK-tema naik kuda 42+ tahun - cewe - gendut - hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema naik kuda 42+ tahun - cewe - gendut - hijab.png"),
        faceIndexTarget: 1,
        newImage: true,
        week: 6,
      },
      {
        id: "T38-FF-45",
        src: require("@/assets/webp/T38-FF-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FF-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "T38-FFH-45",
        src: require("@/assets/webp/T38-FFH-45.webp"),
        srcOri: require("@/assets/allTemplate/T38-FFH-45.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 42+ cewe gendut",
        src: require("@/assets/webp/RK-mancing bersama- 42+ cewe gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 42+ cewe gendut.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-mancing bersama- 42+ cewe gendut hijab",
        src: require("@/assets/webp/RK-mancing bersama- 42+ cewe gendut hijab.webp"),
        srcOri: require("@/assets/allTemplate/RK-mancing bersama- 42+ cewe gendut hijab.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 5,
      },
      {
        id: "RK-tema anak sekolah 42+cewe gendut",
        src: require("@/assets/webp/RK-tema anak sekolah 42+cewe gendut.webp"),
        srcOri: require("@/assets/allTemplate/RK-tema anak sekolah 42+cewe gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "BI-tema anak sekolah - cewe 42+ gendut",
        src: require("@/assets/webp/BI-tema anak sekolah - cewe 42+ gendut.webp"),
        srcOri: require("@/assets/allTemplate/BI-tema anak sekolah - cewe 42+ gendut.png"),
        faceIndexTarget: 3,
        newImage: false,
        week: 4,
      },
      {
        id: "AD-T36-ALT9-WG45-1801",
        src: require("@/assets/webp/AD-T36-ALT9-WG45-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT9-WG45-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "AD-T36-ALT15-WHG45-1801",
        src: require("@/assets/webp/AD-T36-ALT15-WHG45-1801.webp"),
        srcOri: require("@/assets/allTemplate/AD-T36-ALT15-WHG45-1801.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 3,
      },
      {
        id: "TI-T35-ALT2-1001",
        src: require("@/assets/webp/TI-T35-ALT2-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT2-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T35-ALT3-1001",
        src: require("@/assets/webp/TI-T35-ALT3-1001.webp"),
        srcOri: require("@/assets/allTemplate/TI-T35-ALT3-1001.png"),
        faceIndexTarget: 1,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT1-0901",
        src: require("@/assets/webp/AH-T34-ALT1-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT1-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "AH-T34-ALT3-0901",
        src: require("@/assets/webp/AH-T34-ALT3-0901.webp"),
        srcOri: require("@/assets/allTemplate/AH-T34-ALT3-0901.png"),
        faceIndexTarget: 2,
        newImage: false,
        week: 2,
      },
      {
        id: "TI-T34-ALT3-2712",
        src: require("@/assets/webp/TI-T34-ALT3-2712.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT3-2712.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T34-ALT1-2712",
        src: require("@/assets/webp/TI-T34-ALT1-2712.webp"),
        srcOri: require("@/assets/allTemplate/TI-T34-ALT1-2712.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT5-2612",
        src: require("@/assets/webp/AH-T30-ALT5-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT5-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "AH-T30-ALT2-2612",
        src: require("@/assets/webp/AH-T30-ALT2-2612.webp"),
        srcOri: require("@/assets/allTemplate/AH-T30-ALT2-2612.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "JE-T30-ALT4-2212",
        src: require("@/assets/webp/JE-T30-ALT4-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT4-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "JE-T30-ALT2-2212",
        src: require("@/assets/webp/JE-T30-ALT2-2212.webp"),
        srcOri: require("@/assets/allTemplate/JE-T30-ALT2-2212.png"),
        faceIndexTarget: 0,
        newImage: false,
      },
      {
        id: "RK-T4-ALT2-0812",
        src: require("@/assets/webp/RK-T4-ALT2-0812.webp"),
        srcOri: require("@/assets/allTemplate/RK-T4-ALT2-0812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT2-1812",
        src: require("@/assets/webp/TI-T13-ALT2-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT2-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
      {
        id: "TI-T13-ALT5-1812",
        src: require("@/assets/webp/TI-T13-ALT5-1812.webp"),
        srcOri: require("@/assets/allTemplate/TI-T13-ALT5-1812.png"),
        faceIndexTarget: 1,
        newImage: false,
      },
    ],
  },
};

const mutations = {
  setServerSetting(state, payload) {
    state.serverLimit = payload;
  },
  setConnectingTo(state, payload) {
    state.connectingTo = payload;
  },
};

const actions = {
  // Action to perform a POST request with a JSON body
  async hitStableDiffusion(context, payload) {
    return axios({
      method: "post",
      // url: apiUrl,
      // url: state.connectingTo.url,
      url: "https://sd.wirglobal.com/reactor/image",
      // url: "https://s2.duajari.online/reactor/image",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  async testCloud(context, payload) {
    return axios({
      method: "get",
      // url: apiUrl,
      // url: "https://asia-southeast2-jari2-bebe2.cloudfunctions.net/send",
      // url: "https://pokeapi.co/api/v2/pokemon/ditto",
      url: "https://send-vdg7bas5xq-et.a.run.app",
      // url: 'https://stest.duajari.online/reactor/image',
      // url: "https://s2.duajari.online/reactor/image",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
