<template>
  <div class="launchButton" @click="handleClick">Launch</div>
</template>

<script>
export default {
  name: "LaunchButton",
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style>
.launchButton {
  position: absolute;
  bottom: 12%;
  z-index: 33;
  border-radius: 23px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(26.450000762939453px);
  display: flex;
  width: 191px;
  height: 44px;
  padding: 14px 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #575757;
  text-align: center;
  font-family: Adlinnaka;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
