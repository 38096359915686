import axios from "axios";

const state = {};

const mutations = {};

const actions = {
  async sendMail(context, payload) {
    const {
      email,
      //  image,
      filename,
    } = payload;
    const payloadSend = {
      to: email,
      subject: "Terima kasih telah menggunakan fotober2.ai",
      // image,
      filename,
    };
    // const payloadString = JSON.stringify(payloadSend);
    // console.log(payloadString);
    return axios({
      method: "POST",
      // url: "https://us-central1-jari2-bebe2.cloudfunctions.net/sendEmail",
      // url: "https://sendemail-vdg7bas5xq-uc.a.run.app/",
      // url: "https://asia-southeast2-jari2-bebe2.cloudfunctions.net/app/sendEmail",
      // url: "https://jari2-bebe2.firebaseapp.com/sendEmail",
      url: "https://sendemail.duajari.online/sendEmail",
      // url: "https://asia-southeast2-jari2-bebe2.cloudfunctions.net/send",
      // url: "https://futurevolution.id/sendEmail",
      // data: payloadString, // Send JSON string as data
      data: payloadSend, // Send JSON string as data
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  // async sendMail(context, payload) {
  //   try {
  //     var xhr = new XMLHttpRequest();
  //     xhr.open("POST", "https://sendemail.duajari.online/sendEmail", true);
  //     xhr.setRequestHeader("Content-Type", "application/json");
  //     xhr.onreadystatechange = function () {
  //       if (xhr.readyState == 4) {
  //         if (xhr.status == 200) {
  //           // Parse the JSON response, if applicable
  //           var responseData = JSON.parse(xhr.responseText);
  //           console.log(responseData);
  //         } else {
  //           // Handle errors
  //           console.error("Request failed with status " + xhr.status);
  //         }
  //       }
  //     };

  //     const { email, image, filename } = payload;
  //     const payloadSend = {
  //       to: email,
  //       subject: "Terimakasih telah menggunakan fotober2.ai",
  //       image,
  //       filename,
  //     };
  //     const payloadString = JSON.stringify(payloadSend);
  //     // console.log(payloadString);
  //     xhr.send(payloadString);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
