<template>
  <div
    class="h-100 d-flex flex-column justify-content-center align-items-center"
  >
    <!-- class="blurBg d-flex flex-column justify-content-center align-items-center" -->
    <video
      ref="videoPlayer"
      autoplay
      loop
      muted
      playsinline
      crossorigin="anonymous"
      preload="auto"
      class="loadingVideo"
    >
      <source :src="selectedVideo" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div
      class="loadingBarContainer mb-3 mt-3 d-flex flex-column align-items-center"
    >
      <img
        class="gifLoadingPeople"
        src="../assets/video/loadingPeople.gif"
        alt=""
      />
      <img
        v-if="loadingComplete"
        src="../assets/ui/info/loadingBar2.svg"
        alt="loading"
      />
      <img
        v-else-if="loadingSequence == 0"
        src="../assets/ui/info/loadingBar0.svg"
        alt="loading"
      />
      <img v-else src="../assets/ui/info/loadingBar1.svg" alt="loading" />
    </div>

    <div v-if="!loadingComplete" class="loadingTextFont">Mohon Tunggu..</div>
    <div v-else>
      <!-- <ContinueButton @click="goToPreview" /> -->
      <!-- <img
        src="../assets/video/lanjutkan.gif"
        alt="loadingButton"
        class="lanjutkanLoading"
        @click="goToPreview"
      /> -->
      <ContinueButton @click="goToPreview" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContinueButton from "@/components/buttons/ContinueButton.vue";

export default {
  name: "LoadingScreen",
  components: {
    ContinueButton,
  },
  data() {
    return {
      loadingSequence: 0,
      videoSources: [
        require("@/assets/video/1.mp4"),
        require("@/assets/video/2.mp4"),
        require("@/assets/video/3.mp4"),
        require("@/assets/video/4.mp4"),
        require("@/assets/video/5.mp4"),
      ],
    };
  },
  props: ["goToPreview", "loadingComplete"],
  computed: {
    ...mapState({
      selectedGender: (state) => state.global.selectedGender,
      selectedAgeGroup: (state) => state.global.selectedAgeGroup,
      filterDone: (state) => state.global.filterDone,
      selectedTemplate: (state) => state.global.selectedTemplate,
    }),
    checkSubmit() {
      if (this.localGender && this.localAgeGroup) {
        return true;
      } else {
        return false;
      }
    },
    imgRows() {
      const rows = [];
      const colsPerRow = 3;
      for (let i = 0; i < this.frameFilter.length; i += colsPerRow) {
        const row = this.frameFilter.slice(i, i + colsPerRow);
        rows.push(row);
      }
      return rows;
    },
    selectedVideo() {
      // Randomly select a video source
      const randomIndex = Math.floor(Math.random() * this.videoSources.length);
      return this.videoSources[randomIndex];
    },
  },
  methods: {
    setLoadingSequence() {
      setTimeout(() => {
        this.loadingSequence = 1;
      }, 3000);
    },
    handleVideoEnded() {
      // When the video ends, replay the same video
      this.$refs.videoPlayer.currentTime = 0;
      this.$refs.videoPlayer.play();
    },
  },
  mounted() {
    this.setLoadingSequence();
    this.$refs.videoPlayer.addEventListener("ended", this.handleVideoEnded);
  },
  beforeDestroy() {
    // Remove event listener to prevent memory leaks
    this.$refs.videoPlayer.removeEventListener("ended", this.handleVideoEnded);
  },
};
</script>
