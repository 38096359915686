<template>
  <div
    v-if="isInHomeScreen"
    class="idleScreen d-flex justify-content-center w-100"
  >
    <img
      id="logoText"
      src="../assets/ui/logo/logoText2.png"
      class="imgOriginSize"
    />
    <img
      id="idleScreen"
      src="../assets/ui/background/idleScreen2.png"
      class=""
    />
    <LaunchButton @click="changeToCarousel" />
    <img src="../assets/ui/logo/arLogo.png" class="arLogo" />
  </div>
  <div
    v-else
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <!-- class="blurBg d-flex flex-column justify-content-center align-items-center" -->
    <div class="carouselTitleFont mb-3">
      Selamat datang di Fotober2.ai<br />Prabowo Gibran!
    </div>
    <img
      class="mb-3 sizerCarousel"
      src="../assets/ui/info/carouselHumans.png"
      alt=""
    />

    <div
      id="carouselWithIndicators"
      class="carousel slide mb-3"
      data-bs-wrap="false"
    >
      <ol class="carousel-indicators">
        <li
          data-bs-target="#carouselWithIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></li>
        <li
          data-bs-target="#carouselWithIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></li>
        <li
          data-bs-target="#carouselWithIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></li>
        <li
          data-bs-target="#carouselWithIndicators"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></li>
      </ol>
      <div class="carousel-inner mb-5">
        <div class="carousel-item active mb-4">
          <div class="carouselTextFont">{{ carouselText1 }}</div>
        </div>
        <div class="carousel-item mb-4">
          <div class="carouselTextFont">{{ carouselText2 }}</div>
        </div>
        <div class="carousel-item mb-4">
          <div class="carouselTextFont">{{ carouselText3 }}</div>
        </div>
        <div class="carousel-item mb-4">
          <div class="carouselTextFont">
            Dukung kemenangan paslon nomor 2 dengan membagikan hasil foto di
            media sosialmu. Tag
            <a
              href="https://www.instagram.com/prabowo.gibran2?igsh=MXdvY3Q4aGZkNG5oeg=="
              >@prabowo.gibran2</a
            >
            dan
            <a
              href="https://www.instagram.com/fotober2.ai?igsh=ZGNvMm5paGIzeWp1"
              >@fotober2.ai</a
            >
            untuk ikut meramaikan dukungan dari komunitas online. Mari
            bersama-sama membangun semangat persatuan dan mendukung masa depan
            yang lebih baik.
          </div>
        </div>
      </div>
    </div>

    <ContinueButton @click="changeToCarouselOrLaunchApp" />
  </div>
</template>

<script>
import LaunchButton from "@/components/buttons/LaunchButton.vue";
import ContinueButton from "@/components/buttons/ContinueButton.vue";

export default {
  name: "SplashScreen",
  components: {
    LaunchButton,
    ContinueButton,
  },
  data() {
    return {
      splashDuration: 3, //in second
      splashTimerDone: false,
      isInHomeScreen: true,
      carouselElement: "",
      currentCarouselPage: -1,
      carouselText1:
        "Aplikasi ini memberikan kesempatan langka untuk berfoto bersama dengan sosok inspiratif, Bapak Prabowo dan Mas Gibran, pasangan calon presiden dan wakil presiden Indonesia 2024-2029.",
      carouselText2:
        "Nikmati pengalaman berfoto yang unik dengan memilih berbagai kondisi dan latar belakang yang sesuai dengan suasana hatimu saat ini, sehingga hasil foto akan terasa lebih personal dan berkesan.",
      carouselText3:
        "Fotober2.ai Prabowo Gibran tidak hanya memberikan kesempatan untuk merasakan momen spesial, tetapi juga mendekatkanmu dengan pasangan calon nomor 2. Pastikan kamu menggunakan browser Chrome atau safari pada saat menggunakan aplikasi ini.",
      carouselText4:
        "Dukung kemenangan paslon nomor 2 dengan membagikan hasil foto di media sosialmu. Tag @prabowogibran2 dan @fotober2.ai untuk ikut meramaikan dukungan dari komunitas online. Mari bersama-sama membangun semangat persatuan dan mendukung masa depan yang lebih baik.",
    };
  },
  methods: {
    changeToCarouselOrLaunchApp() {
      if (this.currentCarouselPage === 3) {
        this.launchApp();
      } else {
        this.goToNextSlide();
      }
    },
    goToNextSlide() {
      this.carouselElement.next();
    },
    changeToCarousel() {
      this.isInHomeScreen = false;
      setTimeout(() => {
        const theCarouselElement = document.getElementById(
          "carouselWithIndicators"
        );
        this.carouselElement = new window.bootstrap.Carousel(
          theCarouselElement,
          {
            interval: false, // Disable automatic sliding
          }
        );

        // Attach the event listener directly to the DOM element
        theCarouselElement.addEventListener(
          "slid.bs.carousel",
          this.onSlideChanged
        );
      }, 300);
    },
    onSlideChanged(event) {
      this.currentCarouselPage = event.to;
    },
    launchApp() {
      this.$store.commit("global/toggle1", true);
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.carousel) {
      this.carousel.el.removeEventListener(
        "slid.bs.carousel",
        this.onSlideChanged
      );
    }
  },
};
</script>
