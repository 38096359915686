<template>
  <div class="continueButton" @click="handleClick">Lanjutkan</div>
</template>

<script>
export default {
  name: "ContinueButton",
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style>
.continueButton {
  display: flex;
  height: 47px;
  width: 167px;
  padding: 14px 42px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 23px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(26.450000762939453px);
  color: #575757;
  text-align: center;
  font-family: Adlinnaka;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
