<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    // Set the document title when the component is mounted
    // document.title = "Dua Jari Online";
  },
};
</script>
