<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center m-0 p-0"
  >
    <!-- class="blurBg d-flex flex-column justify-content-center align-items-center m-0 p-0" -->
    <img
      id="btnTopLeft"
      alt="backBtn"
      src="../assets/ui/button/backButton.svg"
      @click="resetAll"
    />
    <p class="titleFont">Deskripsikan dirimu</p>
    <p class="descFont">Untuk meningkatkan hasil akurasi foto</p>
    <div class="btn-group row justify-content-center w-100" role="group">
      <!-- Radio button 1 -->
      <div class="col-1"></div>

      <input
        type="radio"
        id="radio1"
        v-model="localGender"
        value="male"
        class="d-none"
        @click="setGender('male')"
      />
      <label
        for="radio1"
        class="custom-radio-label col-4"
        :class="{ active: localGender === 'male' }"
      >
        <div class="col-12 text-center">
          <img
            v-if="localGender === 'male'"
            src="../assets/ui/filter/genderMaleActive.svg"
            alt="Male Active"
            class="img-fluid shadowBox"
          />
          <img
            v-else
            src="../assets/ui/filter/genderMaleNotActive.svg"
            alt="Male Not Active"
            class="img-fluid"
          />
          <p class="genderTextFont mt-2">Laki-Laki</p>
        </div>
      </label>

      <!-- Radio button 2 -->
      <input
        type="radio"
        id="radio2"
        v-model="localGender"
        value="female"
        class="d-none"
        @click="setGender('female')"
      />
      <label
        for="radio2"
        class="custom-radio-label col-4"
        :class="{ active: localGender === 'female' }"
      >
        <div class="col-12 text-center">
          <img
            v-if="localGender === 'female'"
            src="../assets/ui/filter/genderFemaleActive.svg"
            alt="Female Active"
            class="img-fluid shadowBox"
          />
          <img
            v-else
            src="../assets/ui/filter/genderFemaleNotActive.svg"
            alt="Female Not Active"
            class="img-fluid"
          />
          <p class="genderTextFont mt-2">Perempuan</p>
        </div>
      </label>
      <div class="col-1"></div>
    </div>

    <br />
    <div class="d-flex justify-content-center w-100">
      <!-- <div class="custom-select">
            <button @click="toggleDropdown">
              {{ selectedOption || "Select an option" }}
            </button>
            <div class="custom-dropdown" :class="{ open: isDropdownOpen }">
              <div
                v-for="(option, index) in options"
                :key="index"
                class="custom-dropdown-item"
                @click="selectOption(option)"
              >
                {{ option }}
              </div>
            </div>
          </div> -->
      <select
        class="agePlaceholder form-select form-select-sm mb-3"
        v-model="localAgeGroup"
        @change="setAgeGroup"
      >
        <option value="0" disabled selected>Umur</option>
        <option value="1">17-25 Tahun</option>
        <option value="2">26-42 Tahun</option>
        <option value="3">43+ Tahun</option>
      </select>
    </div>
    <div class="d-flex justify-content-center w-100">
      <!-- <div class="custom-select">
            <button @click="toggleDropdown">
              {{ selectedOption || "Select an option" }}
            </button>
            <div class="custom-dropdown" :class="{ open: isDropdownOpen }">
              <div
                v-for="(option, index) in options"
                :key="index"
                class="custom-dropdown-item"
                @click="selectOption(option)"
              >
                {{ option }}
              </div>
            </div>
          </div> -->
      <!-- <select
        class="agePlaceholder form-select form-select-sm mb-3"
        disabled
        v-model="localProvince"
        @change="setProvinceGroup"
      >
        <option value="0" disabled selected>Provinsi</option>
      </select> -->

      <select
        class="agePlaceholder form-select form-select-sm mb-3"
        v-model="localProvince"
        @change="loadCities"
      >
        <option value="0" disabled selected>Provinsi</option>
        <option
          v-for="province in provinces"
          :key="province.code"
          :value="province.code"
        >
          {{ province.name }}
        </option>
      </select>

      <!-- <select
        class="agePlaceholder form-select form-select-sm mb-3"
        v-model="localCity"
        :disabled="!localProvince"
        @change="setCity"
      >
        <option :value="null" disabled>Select City</option>
        <option
          v-for="city in cities"
          :key="city.province_code"
          :value="city.province_code"
        >
          {{ city.name }}
        </option>
      </select> -->
    </div>
    <p class="genderTextFont">Identifikasi Bentuk tubuh :</p>

    <div class="btn-group row justify-content-center w-100" role="group">
      <!-- Radio button 1 -->
      <div class="col-1"></div>

      <input
        type="radio"
        id="radioPosture1"
        v-model="localPosture"
        value="1"
        class="d-none"
        @click="setPosture(1)"
      />
      <label
        for="radioPosture1"
        class="custom-radio-label col-2"
        :class="{ active: localPosture == 1 }"
      >
        <div class="col-12 text-center">
          <img
            v-if="localPosture == 1"
            src="../assets/ui/filter/postureThinActive.svg"
            alt="Male Active"
            class="img-fluid"
          />
          <img
            v-else
            src="../assets/ui/filter/postureThinNotActive.svg"
            alt="Male Not Active"
            class="img-fluid"
          />
        </div>
      </label>

      <!-- Radio button 2 -->
      <!-- <input
        type="radio"
        id="radioPosture2"
        v-model="localPosture"
        value="2"
        class="d-none"
        @click="setPosture(2)"
      />
      <label
        for="radioPosture2"
        class="custom-radio-label col-2"
        :class="{ active: localPosture == 2 }"
      >
        <div class="col-12 text-center">
          <img
            v-if="localPosture == 2"
            src="../assets/ui/filter/postureMedActive.svg"
            alt="Female Active"
            class="img-fluid"
          />
          <img
            v-else
            src="../assets/ui/filter/postureMedNotActive.svg"
            alt="Female Not Active"
            class="img-fluid"
          />
        </div>
      </label> -->
      <!-- Radio button 3 -->
      <input
        type="radio"
        id="radioPosture2"
        v-model="localPosture"
        value="2"
        class="d-none"
        @click="setPosture(2)"
      />
      <label
        for="radioPosture2"
        class="custom-radio-label col-2"
        :class="{ active: localPosture == 2 }"
      >
        <div class="col-12 text-center">
          <img
            v-if="localPosture == 2"
            src="../assets/ui/filter/postureFatActive.svg"
            alt="Female Active"
            class="img-fluid"
          />
          <img
            v-else
            src="../assets/ui/filter/postureFatNotActive.svg"
            alt="Female Not Active"
            class="img-fluid"
          />
        </div>
      </label>
      <div class="col-1"></div>
    </div>
    <br />
    <br />
    <div class="d-flex justify-content-center w-100">
      <div
        :class="{
          submitEnable: checkSubmit,
          submitDisable: !checkSubmit,
        }"
        @click="chooseFilter"
      >
        Pilih
      </div>
    </div>

    <div class="bgPpDark">
      <span class="ppLink" @click="togglePP">Kebijakan & Privasi</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import provinceData from "@/assets/location/provinsi.json";

export default {
  name: "SplashScreen",
  props: ["resetAll", "togglePP"],
  data() {
    return {
      localGender: null,
      localAgeGroup: 0,
      localProvince: 0,
      localCity: {},
      localPosture: 0,

      provinces: provinceData,
      cities: [],
    };
  },
  computed: {
    ...mapState({
      selectedGender: (state) => state.global.selectedGender,
      selectedAgeGroup: (state) => state.global.selectedAgeGroup,
      selectedProvince: (state) => state.global.selectedProvince,
      selectedCity: (state) => state.global.selectedCity,
      selectedPosture: (state) => state.global.selectedPosture,
      filterDone: (state) => state.global.filterDone,
    }),
    checkSubmit() {
      if (
        this.localGender &&
        this.localAgeGroup > 0 &&
        this.localProvince != 0 &&
        this.localPosture > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    setGender(value) {
      this.$store.commit("global/setGender", value);
    },
    setAgeGroup() {
      this.$store.commit("global/setAgeGroup", this.localAgeGroup);
    },

    async loadCities() {
      if (this.selectedProvince) {
        const foundObject = this.provinces.find(
          (obj) => obj.code === this.localProvince
        );
        this.$store.commit("global/setProvinceGroup", foundObject);

        // try {
        //   const { default: citiesData } = await import(
        //     `@/assets/location/${this.selectedProvince}.json`
        //   );
        //   this.cities = citiesData;
        // } catch (error) {
        //   console.error("Error loading cities:", error.message);
        // }
      } else {
        this.cities = [];
      }
    },
    // setCity() {
    //   this.$store.commit("global/setCity", this.localCity);
    // },

    setPosture(value) {
      this.$store.commit("global/setPosture", value);
    },
    chooseFilter() {
      if (
        this.localGender &&
        this.selectedAgeGroup > 0 &&
        this.localProvince != 0 &&
        this.localPosture > 0
      ) {
        this.$store.commit("global/toggle2", true);
      }
    },
  },
  created() {
    if (this.selectedGender !== null) this.localGender = this.selectedGender;
    if (Object.keys(this.selectedProvince).length > 0)
      this.localProvince = this.selectedProvince.code;
    if (this.selectedAgeGroup > 0) this.localAgeGroup = this.selectedAgeGroup;
    if (this.selectedPosture > 0) this.localPosture = this.selectedPosture;
  },
};
</script>
