import { db, serverTimestamp } from "../../firebase.js";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  doc,
  increment,
} from "firebase/firestore";
/**
 * Step:
 * 1. Splash screen
 * 2. Launch screen
 * 3. Filter screen
 * 4. Template screen
 * 5. How to play screen (HTP)
 * 6. Loading screen
 * 7. Preview screen
 */
const state = {
  splashDone: false,
  selectedGender: null,
  selectedAgeGroup: 0,
  // 1 = 17-25
  // 2 = 26-42
  // 3 = 43+
  selectedProvince: {},
  selectedCity: {},
  selectedPosture: 0,
  // 1 = Gemuk
  // 2 = Kurus
  filterDone: false,
  selectedTemplate: "",
  isTemplateSelected: false,
  email: "",
  currentDocumentId: "",
};

const mutations = {
  totalResetState(state) {
    state.splashDone = false;
    state.selectedGender = null;
    state.selectedAgeGroup = 0;
    state.selectedPosture = 0;
    state.filterDone = false;
    state.selectedTemplate = "";
    state.isTemplateSelected = false;
  },
  backToFilter(state) {
    state.filterDone = false;
    state.isTemplateSelected = false;
    state.selectedTemplate = "";
  },
  backToTemplate(state) {
    state.isTemplateSelected = false;
    state.selectedTemplate = "";
  },
  toggle1(state, payload) {
    // Splash to launch
    state.splashDone = payload;
  },
  toggle2(state, payload) {
    // filter to template
    state.filterDone = payload;
  },
  toggle3(state, payload) {
    // tenplate to htp
    state.isTemplateSelected = payload;
  },
  setGender(state, payload) {
    state.selectedGender = payload;
  },
  setAgeGroup(state, payload) {
    state.selectedAgeGroup = payload;
  },
  setProvinceGroup(state, payload) {
    state.selectedProvince = payload;
  },
  setCity(state, payload) {
    state.selectedCity = payload;
  },
  setPosture(state, payload) {
    state.selectedPosture = payload;
  },
  setSelectedTemplate(state, payload) {
    state.selectedTemplate = payload;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
};

const actions = {
  async writeFiltersData({ state, commit }, payload) {
    try {
      const dataInput = {
        ageGroup: parseInt(state.selectedAgeGroup),
        posture: parseInt(state.selectedPosture),
        gender: state.selectedGender,
        province: state.selectedProvince.name,
        templateId: state.selectedTemplate,
        timestamp: serverTimestamp(),
      };

      if (payload.email != "") {
        dataInput.email = payload.email;
        if (payload.email != state.email) commit("setEmail", payload.email);
      }

      const docRef = await addDoc(collection(db, "filters"), dataInput);
      console.log("ref: ", docRef.id);
      return docRef.id;
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  },

  async addEmailToFilter({ state, commit }, payload) {
    try {
      if (payload.email == state.email) return;
      commit("setEmail", payload.email);
      const docId = doc(db, "filters", payload.currentDocumentId);
      await updateDoc(docId, {
        email: payload.email,
      });
    } catch (error) {
      console.log(error);
    }
  },

  async getServerSetting() {
    try {
      const querySnapshot = await getDocs(collection(db, "Setting"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return data;
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  },

  async getServers() {
    try {
      const querySnapshot = await getDocs(collection(db, "Server"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      return data;
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  },

  async incrementServerCounting(context, payload) {
    try {
      const { pickedServer, isIncrement } = payload;
      console.log("payload: ", payload);
      console.log("server id: ", pickedServer.id);
      const serverRef = doc(db, "Server", pickedServer.id);
      // const serverRef = doc(db, "testServer", "server2test");
      await updateDoc(serverRef, {
        counting: isIncrement ? increment(1) : increment(-1),
      });
      console.log("Counting field incremented successfully!");
    } catch (error) {
      console.error("Error incrementing counting field:", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
