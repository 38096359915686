<template>
  <div
    v-if="isIphoneChrome"
    class="h-100 d-flex flex-column justify-content-center align-items-center useSafari"
  >
    <video
      autoplay
      loop
      muted
      playsinline
      crossorigin="anonymous"
      preload="auto"
      class="blurBgAnimate"
    >
      <source src="../assets/video/background2.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <img
      src="../assets/ui/info/useSafari.png"
      alt="use safari"
      class="useSafariImg"
    />
  </div>
  <div v-else-if="isPortrait" class="h-100">
    <div :class="{ invisible: !isVisible, versionClass: isVisible }">
      v1.3.0
    </div>
    <div class="fallbackBG"></div>
    <div class="toggler" @click.stop="toggleVisibility"></div>
    <video
      autoplay
      loop
      muted
      playsinline
      crossorigin="anonymous"
      preload="auto"
      class="blurBgAnimate"
    >
      <source src="../assets/video/background2.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <PrivacyPolicyScreen
      v-if="inPrivacyPolicy"
      class="h-100"
      :togglePP="togglePP"
    />
    <div
      v-else-if="!serverAvailable"
      class="h-100 d-flex flex-column justify-content-center align-items-center cdTextNormal"
    >
      <div class="mb-4">Saat ini server sedang penuh</div>
      <div
        v-if="countdownDisplay === 0"
        class="btnCobaLagiActive btnCobaLagiActiveText mb-4"
        @click="checkServerStatus"
      >
        <img src="../assets/ui/icon/refreshIconOn.svg" alt="refresh" />Coba lagi
      </div>
      <div v-else class="btnCobaLagiNotActive btnCobaLagiNotActiveText mb-4">
        <img src="../assets/ui/icon/refreshIconOff.svg" alt="refresh" />Coba
        lagi
      </div>
      Mohon mencoba kembali dalam<br />
      <div>
        <span class="cdTextBold">{{ countdownDisplay }}</span> detik
      </div>
    </div>
    <div
      v-else-if="isDownloadComicScreen"
      class="h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <img
        id="btnTopLeft"
        alt="backBtn"
        src="../assets/ui/button/backButton.svg"
        @click="toggleDownloadComic"
      />

      <img
        src="../assets/ui/comic/coverComic1.svg"
        alt=""
        class="comicCover1 mb-2"
      />
      <img
        src="../assets/ui/button/unduhIcon.svg"
        alt=""
        @click="downloadComicPDF(1)"
      />
    </div>
    <div
      v-else-if="errorReturn"
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <img @click="retakeImage" src="../assets/ui/icon/iconError.svg" alt="" />
    </div>
    <div
      v-else-if="loadingCheckServer"
      class="h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <img
        class="gifLoadingPeopleServer"
        src="../assets/video/loadingPeopleServer.gif"
        alt=""
      />
      <div class="loadingTextFont">Menghubungkan...</div>
    </div>
    <SplashScreen v-else-if="!splashDone" class="h-100" />
    <FilterScreen
      v-else-if="!filterDone"
      :resetAll="resetAll"
      class="h-100"
      :togglePP="togglePP"
    />
    <LoadingScreen
      v-else-if="loading && filterDone"
      :goToPreview="goToPreview"
      :loadingComplete="loadingComplete"
    />
    <div
      id="htpScreen"
      v-else-if="isTemplateSelected"
      class="h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="../assets/ui/info/htpAll.svg" alt="" />
      <br />
      <div>
        <img
          src="../assets/ui/button/camera.svg"
          alt=""
          @click="handleTakePicture"
        />
      </div>
    </div>

    <div
      v-else-if="showResult && filterDone"
      class="h-100 blurResultBg paddingTop15 d-flex justify-content-center noScroll h-100"
    >
      <div class="coverReal"></div>
      <div id="printedArea" ref="printedArea">
        <img class="resultImage" :src="processedImageSrc" alt="" />
        <!-- <img class="frameImage" src="../assets/ui/frame169.png" alt="" /> -->
        <img class="frameImage" src="../assets/ui/4R-1.png" alt="" />
      </div>
      <div class="d-flex flex-column align-items-center">
        <img class="previewImage" :src="processedImageSrc" alt="" />
        <!-- <img class="frameImagePreview" src="../assets/ui/frame169.png" alt="" /> -->
        <img class="frameImagePreview" src="../assets/ui/4R-1.png" alt="" />

        <!-- <img
          src="../assets/ui/button/downloadButton.svg"
          alt="download"
          class="overlapPreview"
          @click="toggleShowEmailForm"
        /> -->
        <div class="unduhKecil overlapPreview" @click="toggleShowEmailForm">
          Unduh
        </div>
        <img
          src="../assets/ui/button/unduhKomik.png"
          alt="read komik"
          class="sameWidthToPreviewImage mb-3"
          @click="toggleDownloadComic"
        />
        <img
          src="../assets/ui/button/halamanAwal.svg"
          alt="backBtn"
          @click="backToFilterRetake"
        />
      </div>

      <div
        v-if="showEmailForm"
        class="emailLayer d-flex flex-column justify-content-center align-items-center"
      >
        <div class="emailFormContainer">
          <div class="emailFormTitle pt-2">Mohon masukan email</div>
          <input
            type="email"
            class="emailFormInput form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            placeholder="Masukan email kamu"
            v-model="emailInput"
          />
          <div class="emailInfoContainer">
            <span v-if="emailSubmited && isValidEmail" class="emailGood"
              ><img src="../assets/ui/icon/iconWarnGreen.svg" alt="" />Email
              telah sesuai</span
            >
            <span v-else-if="emailSubmited && emailInput == ''" class="emailBad"
              ><img src="../assets/ui/icon/iconWarnRed.svg" alt="" />Harap
              masukkan email</span
            >
            <span v-else-if="emailSubmited && !isValidEmail" class="emailBad"
              ><img src="../assets/ui/icon/iconWarnRed.svg" alt="" />Email tidak
              ditemukan</span
            >
          </div>
          <div
            :class="{
              unduhButton: !emailDownloadDisabled,
              disabledunduhButton: emailDownloadDisabled,
            }"
            @click.stop="downloadAfterEmail"
          >
            Unduh
          </div>
        </div>
      </div>
    </div>
    <TemplateScreen
      v-else
      :backToFilter="backToFilter"
      :frameFilter="frameFilter"
    />
  </div>
  <div
    v-else
    class="landscapeDisplay d-flex justify-content-center align-items-center"
  >
    <img src="../assets/ui/info/landscape.svg" alt="landscape" />
  </div>
</template>

<script>
// @ is an alias to /src
import SplashScreen from "@/components/SplashScreen.vue";
import FilterScreen from "@/components/FilterScreen.vue";
import TemplateScreen from "@/components/TemplateScreen.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import PrivacyPolicyScreen from "@/components/PrivacyPolicyScreen.vue";
import html2canvas from "html2canvas";
import { mapState } from "vuex";

export default {
  name: "HomeView",
  components: {
    SplashScreen,
    FilterScreen,
    TemplateScreen,
    LoadingScreen,
    PrivacyPolicyScreen,
  },
  computed: {
    ...mapState({
      splashDone: (state) => state.global.splashDone,
      filterDone: (state) => state.global.filterDone,
      isTemplateSelected: (state) => state.global.isTemplateSelected,
      selectedGender: (state) => state.global.selectedGender,
      selectedAgeGroup: (state) => state.global.selectedAgeGroup,
      selectedPosture: (state) => state.global.selectedPosture,
      selectedTemplate: (state) => state.global.selectedTemplate,
      email: (state) => state.global.email,
    }),
    frameFilter() {
      if (this.selectedGender == "male") {
        if (this.selectedPosture == 1) {
          if (this.selectedAgeGroup == 1) {
            return this.$store.state.photo.frameGroupData.group1;
          } else if (this.selectedAgeGroup == 2) {
            return this.$store.state.photo.frameGroupData.group2;
          } else if (this.selectedAgeGroup == 3) {
            return this.$store.state.photo.frameGroupData.group3;
          }
        } else if (this.selectedPosture == 2) {
          if (this.selectedAgeGroup == 1) {
            return this.$store.state.photo.frameGroupData.group4;
          } else if (this.selectedAgeGroup == 2) {
            return this.$store.state.photo.frameGroupData.group5;
          } else if (this.selectedAgeGroup == 3) {
            return this.$store.state.photo.frameGroupData.group6;
          }
        }
      } else if (this.selectedGender == "female") {
        if (this.selectedPosture == 1) {
          if (this.selectedAgeGroup == 1) {
            return this.$store.state.photo.frameGroupData.group7;
          } else if (this.selectedAgeGroup == 2) {
            return this.$store.state.photo.frameGroupData.group8;
          } else if (this.selectedAgeGroup == 3) {
            return this.$store.state.photo.frameGroupData.group9;
          }
        } else if (this.selectedPosture == 2) {
          if (this.selectedAgeGroup == 1) {
            return this.$store.state.photo.frameGroupData.group10;
          } else if (this.selectedAgeGroup == 2) {
            return this.$store.state.photo.frameGroupData.group11;
          } else if (this.selectedAgeGroup == 3) {
            return this.$store.state.photo.frameGroupData.group12;
          }
        }
      }
      return [];
    },
    isValidEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.emailInput);
    },
  },
  data() {
    return {
      loading: false,
      showResult: false,
      targetFaceIndex: 0,
      htpPage: 1,
      loadingComplete: false,
      // preview
      processedImageSrc: "",
      photoSrc: "",
      templateSrc: "",
      isDownloadScreen: false,

      isDropdownOpen: false,
      testings: false,
      divIds: [],
      deleteAreaActive: false,

      columnNumber: 3,
      errorReturn: false,
      isVisible: false,
      inPrivacyPolicy: false,

      serverAvailable: true,
      countdownIndex: -1,
      countdownList: [10, 20, 30, 40, 50, 60, 3600],
      countdownDisplay: 0,
      selectedServer: {},

      isPortrait: window.matchMedia("(orientation: portrait)").matches,
      isDownloadComicScreen: false,
      showEmailForm: false,
      emailInput: "",
      emailDownloadDisabled: false,

      loadingCheckServer: false,
      emailSubmited: false,

      imageDataUrlDownload: "",
      filterRecorder: false,

      isIphoneChrome: false,
      currentDocumentId: "",

      generateCount: 0,
      generateTimeout: 300 * 1000,

      showPaidEmailForm: false,
    };
  },
  methods: {
    // device orientation
    checkOrientation() {
      this.isPortrait = window.matchMedia("(orientation: portrait)").matches;
    },
    handleOrientationChange() {
      this.checkOrientation();
    },

    // Screen Transition
    nextHtp() {
      this.htpPage = this.htpPage + 1;
    },
    togglePP() {
      this.inPrivacyPolicy = !this.inPrivacyPolicy;
    },
    goToPreview() {
      this.$store.commit("global/toggle3", false);
      this.showResult = true;
      this.loading = false;
    },
    toggleDownloadComic() {
      if (!this.showEmailForm)
        this.isDownloadComicScreen = !this.isDownloadComicScreen;
    },
    toDownloadScreen() {
      this.isDownloadScreen = true;
    },
    toggleShowEmailForm() {
      this.showEmailForm = !this.showEmailForm;
    },
    async toggleVisibility() {
      this.isVisible = !this.isVisible;
      // this.$store.dispatch("photo/testCloud", { name: "hehe" });
    },

    // Back / reset
    resetAll() {
      this.loading = false;
      this.showResult = false;
      this.targetFaceIndex = 0;
      this.htpPage = 1;
      this.photoSrc = "";
      this.templateSrc = "";
      this.imageDataUrlDownload = "";
      this.filterRecorder = false;
      this.$store.commit("global/setSelectedTemplate", "");
      this.$store.commit("global/totalResetState");
    },
    backToFilter() {
      this.htpPage = 1;
      this.imageDataUrlDownload = "";
      this.filterRecorder = false;
      this.$store.commit("global/setSelectedTemplate", "");
      this.$store.commit("global/backToFilter");
    },
    backToFilterRetake() {
      if (this.showEmailForm) return;
      this.htpPage = 1;
      this.loading = false;
      this.errorReturn = false;
      this.showResult = false;
      this.processedImageSrc = "";
      this.htpPage = 1;
      this.loadingComplete = false;
      this.isDownloadScreen = false;
      this.photoSrc = "";
      this.templateSrc = "";
      this.imageDataUrlDownload = "";
      this.filterRecorder = false;
      this.$store.commit("global/setSelectedTemplate", "");
      this.$store.commit("global/backToFilter");
    },
    retakeImage() {
      this.loading = false;
      this.errorReturn = false;
      this.showResult = false;
      this.processedImageSrc = "";
      this.htpPage = 1;
      this.loadingComplete = false;
      this.isDownloadScreen = false;
      this.photoSrc = "";
      this.templateSrc = "";
      this.imageDataUrlDownload = "";
      this.filterRecorder = false;
      this.$store.commit("global/setSelectedTemplate", "");
      this.$store.commit("global/backToTemplate");
    },
    resetGenerateCount() {
      this.generateCount = 0;
    },

    // // server selection
    // decreaseServerCounting() {
    //   this.$store.dispatch("global/incrementServerCounting", {
    //     pickedServer: this.selectedServer,
    //     isIncrement: false,
    //   });
    //   this.selectedServer = {};
    // },
    async checkServerStatus() {
      this.loadingCheckServer = true;
      this.$store
        .dispatch("global/getServers")
        .then((response) => {
          console.log(response);
          this.loadingCheckServer = false;

          const singleServerLimit = this.$store.state.photo.serverLimit;
          const totalLimit = singleServerLimit * response.length;
          let currentServerCount = 0;
          let pickedServer = null;

          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            if (
              pickedServer === null &&
              element.data.counting < singleServerLimit
            )
              pickedServer = element;
            else if (
              pickedServer !== null &&
              pickedServer.data.counting > element.data.counting &&
              element.data.counting < singleServerLimit
            )
              pickedServer = element;
            currentServerCount += element.data.counting;
          }

          if (totalLimit <= currentServerCount) {
            this.serverAvailable = false;
            this.setCountdown();
            console.log("server full");
            return { status: "full" };
          }
          this.resetCountdown();
          this.selectedServer = pickedServer;
          this.$store.commit("photo/setConnectingTo", pickedServer.data);
          const payload = {
            source_image: this.photoSrc,
            target_image: this.templateSrc,
            source_faces_index: [0],
            face_index: [this.targetFaceIndex],
            upscaler: "None",
            scale: 1,
            upscale_visibility: 1,
            face_restorer: "GFPGAN",
            restorer_visibility: 1,
            codeformer_weight: 0.5,
            restore_first: 1,
            model: "inswapper_128.onnx",
            gender_source: 0,
            gender_target: 0,
            save_to_file: 0,
            result_file_path: "",
            device: "CUDA",
            mask_face: 1,
            select_source: 0,
            face_model: "None",
            token:
              "f9f5b5148893f0d5f78a2c42163eddd3d250a4e446d147e3f2b91130a49eab5a7d600771c8ea4860f02badd6fe8eed984751e1d3c7507114a23234c305154887",
          };

          // window.gtag("event", "start_generate", {
          //   event_category: "generate",
          //   event_label: "start",
          // });

          const payloadFilterFirebase = {
            email: this.emailInput,
          };
          this.$store
            .dispatch("global/writeFiltersData", payloadFilterFirebase)
            .then((response) => {
              console.log("write filter success");
              this.currentDocumentId = response;
            })
            .catch(() => {
              console.log("error firebase");
            });

          // // counting increment
          this.$store.dispatch("global/incrementServerCounting", {
            pickedServer: this.selectedServer,
            isIncrement: true,
          });
          return this.$store.dispatch("photo/hitStableDiffusion", payload);
        })
        .then((response2) => {
          if (response2.status == "full") return;
          // window.gtag("event", "finish_generate", {
          //   event_category: "generate",
          //   event_label: "finish",
          // });
          const formattedBase64 = `data:image/jpeg;base64,${response2.data.image}`;
          this.processedImageSrc = formattedBase64;
          this.photoSrc = "";
          this.templateSrc = "";
          this.loadingComplete = true;
        });
    },

    startCountdown() {
      this.timer = setInterval(() => {
        this.countdownDisplay--;
        if (this.countdownDisplay === 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    setCountdown() {
      if (this.countdownIndex < 6) this.countdownIndex++;
      this.countdownDisplay = this.countdownList[this.countdownIndex];
      this.startCountdown();
    },
    resetCountdown() {
      this.serverAvailable = true;
      this.countdownIndex = -1;
      this.countdownDisplay = 0;
    },

    // image related (create, check, download, convert)
    iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    manageInput() {
      // Check if there are any existing input elements
      const existingInputs = document.querySelectorAll('input[type="file"]');
      if (existingInputs.length > 0) {
        existingInputs.forEach((input) => {
          input.parentNode.removeChild(input);
        });
      }
    },
    async takePicture() {
      if (this.selectedTemplate !== "") {
        if (this.iOS()) {
          this.manageInput();
          const inputElement = document.createElement("input");
          inputElement.type = "file";
          inputElement.accept = "image/*";
          inputElement.capture = "camera";
          inputElement.style.display = "none";
          return new Promise((resolve, reject) => {
            document.body.appendChild(inputElement);
            inputElement.addEventListener(
              "change",
              function handleChange(event) {
                inputElement.removeEventListener("change", handleChange);
                const selectedImage = event.target.files[0];
                if (selectedImage) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const base64Image = e.target.result.split(",")[1];
                    const formattedBase64Image = `data:image/jpeg;base64,${base64Image}`;
                    resolve(formattedBase64Image);
                  };
                  reader.onerror = (error) => {
                    console.error("Error reading file:", error);
                    reject(error);
                  };
                  reader.readAsDataURL(selectedImage);
                } else {
                  reject(new Error("No image selecteda"));
                }
              }
            );
            // Trigger a click on the input element to open the file picker
            inputElement.click();
          })
            .finally(() => {
              // Remove the input element from the DOM in the finally block
              document.body.removeChild(inputElement);
            })
            .catch((error) => {
              console.error("Error loading image:", error);
            });
        } else {
          const inputElement = document.createElement("input");
          inputElement.type = "file";
          inputElement.accept = "image/*";
          inputElement.capture = "camera";
          inputElement.click();

          return new Promise((resolve, reject) => {
            inputElement.addEventListener("change", (event) => {
              const selectedImage = event.target.files[0];
              if (selectedImage) {
                const reader = new FileReader();

                reader.onload = (e) => {
                  const base64Image = e.target.result.split(",")[1];
                  const formattedBase64Image = `data:image/jpeg;base64,${base64Image}`;
                  resolve(formattedBase64Image);
                };

                reader.readAsDataURL(selectedImage);
              } else {
                reject(new Error("No image selecteda"));
              }
            });
          });
        }
      } else {
        return Promise.reject(new Error("No template selecteda"));
      }
    },
    async convertToBase64() {
      const foundObject = this.frameFilter.find(
        (obj) => obj.id === this.selectedTemplate
      );
      let imagePath = foundObject.srcOri;
      this.targetFaceIndex = foundObject.faceIndexTarget;
      try {
        const loadImage = (src) => {
          return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
            image.src = src;
          });
        };
        const image = await loadImage(imagePath);
        image.src = imagePath;

        // Create a canvas element
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image onto the canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);

        // Get the base64-encoded data from the canvas
        const base64Image = canvas.toDataURL("image/jpeg");
        return base64Image;
      } catch (error) {
        console.error("Error converting image to Base64:", error);
        throw error;
      }
    },
    handleTakePicture() {
      this.takePicture()
        .then((base64Image) => {
          this.loading = true;
          this.photoSrc = base64Image;
          return this.convertToBase64(this.selectedTemplate);
        })
        .then((templateBase64) => {
          this.templateSrc = templateBase64;
          this.checkServerStatus();
        })
        .catch((error) => {
          console.error("Error API:", error.message);
          // this.decreaseServerCounting();
          this.errorReturn = true;
        });
    },

    // download and share
    async downloadTheCanvas() {
      const divToDownload = this.$refs.printedArea;
      if (this.imageDataUrlDownload !== "") {
        this.executeDownloadImage(this.imageDataUrlDownload);
        return;
      }
      // html2canvas(divToDownload, { width: 1080, height: 1920, scale: 1 }).then(
      html2canvas(divToDownload, { width: 1205, height: 1819, scale: 1 }).then(
        (canvas) => {
          this.imageDataUrlDownload = canvas.toDataURL("image/png");
          const imageDataUrl = canvas.toDataURL("image/png");
          this.executeDownloadImage(imageDataUrl);
        }
      );
    },
    async executeDownloadImage(imgValue) {
      const now = new Date();
      const simplifiedDate = [now.getTime()]
        .map((n) => n.toString().padStart(2, "0"))
        .join("");
      const downloadLink = document.createElement("a");
      downloadLink.href = imgValue;
      downloadLink.download = `fotober2ai_${simplifiedDate}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // // // // send email
      // const payload = {
      //   email: this.emailInput,
      //   image: imgValue,
      //   filename: `fotober2ai_${simplifiedDate}.png`,
      // };
      // this.$store
      //   .dispatch("email/sendMail", payload)
      //   .then((data) => {
      //     console.log(data);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     console.log(err);
      //   });
    },
    shareImage() {
      navigator.clipboard.writeText(
        "Jangan lewatkan keseruan berfoto yang tak terlupakan dengan pasangan calon nomor 2! Ayo, kunjungi segera Fotober2.ai Prabowo Gibran! 📸🇮🇩 #PrabowoGibran #Fotober2.aiPrabowoGibran"
      );

      let imageLink = this.processedImageSrc;
      fetch(imageLink)
        .then((res) => res.blob())
        .then((data2) => {
          const file = new File([data2], "picture.jpeg", {
            type: data2.type,
          });
          const filesArray = [file];
          if (navigator.canShare && navigator.canShare({ files: filesArray })) {
            navigator
              .share({
                title: "",
                text: "",
                files: filesArray,
              })
              // .then(() => {
              //  // share success
              // })
              .catch((error) => {
                //  // share failed
                console.log("here: ", "Sharing failed", error);
              });
          } else {
            // share not supported
          }
        });
    },
    downloadComicPDF(comicNumber) {
      // let comicName = "";
      // switch (comicNumber) {
      //   case 1:
      //     comicName = "Komik 1.pdf";
      //     break;

      //   default:
      //     break;
      // }
      // const pdfPath = `/pdfs/${comicName}`;
      // const link = document.createElement("a");
      // link.href = pdfPath;
      // link.download = comicName;

      // link.click();
      // if (document.body.contains(link)) {
      //   document.body.removeChild(link);
      // }
      let comicName = "";
      switch (comicNumber) {
        case 1:
          comicName = "Komik 1.pdf";
          break;
        default:
          break;
      }

      const pdfPath = `/pdfs/${comicName}`;
      const link = document.createElement("a");
      link.href = pdfPath;
      link.download = comicName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async downloadAfterEmail() {
      this.emailSubmited = true;
      if (this.isValidEmail && !this.emailDownloadDisabled) {
        console.log("Button email unduh clicked");
        this.emailDownloadDisabled = true;
        const payload = {
          email: this.emailInput,
          currentDocumentId: this.currentDocumentId,
        };
        if (this.filterRecorder == true) {
          this.downloadTheCanvas();
          this.emailDownloadDisabled = false;
          this.toggleShowEmailForm();
          return;
        }
        // window.gtag("event", "generated_image_downloaded", {
        //   event_category: "generate",
        //   event_label: "download generated image",
        // });

        if (this.emailInput != this.email) {
          this.$store
            .dispatch("global/addEmailToFilter", payload)
            .then(() => {
              console.log("edit filter email");
              this.filterRecorder = true;
              this.downloadTheCanvas();
              this.emailDownloadDisabled = false;
              this.toggleShowEmailForm();
            })
            .catch(() => {
              this.emailDownloadDisabled = false;
              this.toggleShowEmailForm();
            });
        } else {
          this.filterRecorder = true;
          this.downloadTheCanvas();
          this.emailDownloadDisabled = false;
          this.toggleShowEmailForm();
        }
      } else {
        console.log("Invalid email. Button click not allowed.");
      }
    },
  },
  mounted() {
    window.addEventListener("beforeunload", function (e) {
      var confirmationMessage = "Are you sure you want to leave?";
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    });
    this.checkOrientation();
    if (this.email) this.emailInput = this.email;
    if (this.$browserDetect.isChromeIOS) {
      this.isIphoneChrome = true;
    }

    window
      .matchMedia("(orientation: portrait)")
      .addEventListener("change", this.handleOrientationChange);

    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });

    this.$store.dispatch("global/getServerSetting").then((data) => {
      this.$store.commit(
        "photo/setServerSetting",
        data[0].data.limitationServer
      );
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window
      .matchMedia("(orientation: portrait)")
      .removeEventListener("change", this.handleOrientationChange);
  },
};
</script>
