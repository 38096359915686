<template>
  <div class="custom-container">
    <img
      id="btnTopLeft"
      alt="backBtn"
      src="../assets/ui/button/backButton.svg"
      @click="backToFilter"
    />
    <div v-if="frameFilter.length > 0" class="scrollableY">
      <!-- <div v-if="frameFilter.length > 0" class="blurBg scrollableY"> -->
      <p class="titleFont paddingTop10">Pilih foto</p>
      <p class="descFont">Untuk menciptakan hasil foto sesuai keinginanmu</p>
      <div class="container scrollableY marginBotTemplate">
        <div
          v-for="(group, groupName) in imgRows"
          :key="groupName"
          class="row justify-content-center"
        >
          <div
            v-for="frame in group"
            :key="frame.id"
            class="col-3 mb-3 eachImageTemplate"
            @click="selectTemplate(frame.id)"
          >
            <img
              v-if="frame.newImage"
              src="../assets/ui/icon/newImage.png"
              alt="new"
              class="newImage"
            />
            <img
              :src="frame.src"
              :alt="'Image ' + frame.id"
              class="template-img loading"
              :class="{ highlighted: selectedTemplate === frame.id }"
            />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center w-100 fixed-fab-container">
        <div
          :class="{
            submitEnable: selectedTemplate !== '',
            submitDisable: selectedTemplate == '',
          }"
          @click="toHtpScreen"
        >
          Pilih
        </div>
      </div>
      <!-- <img
        class="fixed-fab-bg"
        src="../assets/ui/background/bgSubmit.svg"
        alt=""
      /> -->

      <div class="fixed-fab-bg"></div>
    </div>
    <div
      v-else
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <!-- class="blurBg d-flex flex-column justify-content-center align-items-center" -->
      <img src="../assets/ui/info/segeraHadir.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TemplateScreen",
  data() {
    return {
      columnNumber: 3,
    };
  },
  props: ["backToFilter", "frameFilter"],
  computed: {
    ...mapState({
      selectedGender: (state) => state.global.selectedGender,
      selectedAgeGroup: (state) => state.global.selectedAgeGroup,
      filterDone: (state) => state.global.filterDone,
      selectedTemplate: (state) => state.global.selectedTemplate,
    }),
    checkSubmit() {
      if (this.localGender && this.localAgeGroup) {
        return true;
      } else {
        return false;
      }
    },
    imgRows() {
      const rows = [];
      const colsPerRow = 3;
      for (let i = 0; i < this.frameFilter.length; i += colsPerRow) {
        const row = this.frameFilter.slice(i, i + colsPerRow);
        rows.push(row);
      }
      return rows;
    },
  },
  methods: {
    selectTemplate(templateNumber) {
      this.$store.commit("global/setSelectedTemplate", templateNumber);
    },
    toHtpScreen() {
      if (this.selectedTemplate !== "")
        this.$store.commit("global/toggle3", true);
    },
  },
};
</script>
