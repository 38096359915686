import Vue from "vue";
import Vuex from "vuex";
import photo from "./photo";
import global from "./global";
import email from "./email";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    firstTime: true, // for splash
    firstTimeDownload: true,
  },
  modules: {
    photo,
    global,
    email,
  },
});

export default store;
