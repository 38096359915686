<template>
  <div class="scrollableY d-flex flex-column align-items-center">
    <img src="../assets/ui/info/ppText.png" alt="" class="ppSize" />
    <ContinueButton @click="togglePP" class="mb-5" />
  </div>
</template>

<script>
import ContinueButton from "@/components/buttons/ContinueButton.vue";

export default {
  name: "PrivacyPolicyScreen",
  components: {
    ContinueButton,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: ["togglePP"],
};
</script>
