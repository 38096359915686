// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, FieldValue, serverTimestamp } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcc37hKAARRNYEGorq0CPiYCFdhMzf9RA",
  authDomain: "jari2-bebe2.firebaseapp.com",
  projectId: "jari2-bebe2",
  storageBucket: "jari2-bebe2.appspot.com",
  messagingSenderId: "932790287881",
  appId: "1:932790287881:web:382ad5105f261c89f3c2d8",
  measurementId: "G-H05E4NWJX2",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp); // Get Firestore instance

export { db, FieldValue, serverTimestamp };
